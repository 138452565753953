import { Component } from '@angular/core';
// import * as data from '../../../../git-version.json'

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="col-md-5 moka-logo"> 
    </div>
    <div class="col-md-7 moka-version"> 
      <span class="created-by"> 
        <img src="assets/images/cash-vision/CashVision-2.png" alt="moka icon" style="height:50px;">
      </span>
    </div>
  `,
})
export class FooterComponent {
  // public git = data;
}
