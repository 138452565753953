import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js
  public apiUrlTest: string = 'https://apimk.mokacloud.com.br';
  public apiUrlProd: string = 'https://apimk.mokacloud.com.br';

  // public apiUrlTest: string;
  // public apiUrlProd: string;

  constructor() { }
}
