<img class='vision-logo' src="../../../assets/images/cash-vision/CashVision-18.png">

<div class='login'>


    <h1 id="title" class="title">Bem vindo.</h1>
    <p class="sub-title">Entre com seu usuário e senha</p>

    <nb-alert *ngIf="loginError && !submitted" outline="danger" role="alert">
        <ul class="alert-message-list">
            <li class="alert-message">O usuário e senha não conferem. Tente novamente.</li>
        </ul>
    </nb-alert>

    <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
        <ul class="alert-message-list">
            <li *ngFor="let message of messages" class="alert-message">Login efetuado com sucesso!</li>
        </ul>
    </nb-alert>

    <form (ngSubmit)="makeLogin()" #form="ngForm" aria-labelledby="title">

        <div class="form-control-group">
            <label class="label" for="input-email"></label>
            <input nbInput fullWidth [(ngModel)]="input.username" #email="ngModel" name="username" id="input-email"
                placeholder="Usuário" autofocus [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
                [required]="getConfigValue('forms.validation.email.required')"
                [attr.aria-invalid]="email.invalid && email.touched ? true : null">
            <ng-container *ngIf="email.invalid && email.touched">
                <p class="error-message" *ngIf="email.errors?.required">
                    Digite o seu usuário!
                </p>
            </ng-container>
        </div>

        <div class="form-control-group">
            <label class="label" for="input-password"></label>
            <input nbInput fullWidth [(ngModel)]="input.passwd" #password="ngModel" name="passwd" type="password"
                id="input-password" placeholder="Senha"
                [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
                [required]="getConfigValue('forms.validation.password.required')"
                [minlength]="getConfigValue('forms.validation.password.minLength')"
                [maxlength]="getConfigValue('forms.validation.password.maxLength')"
                [attr.aria-invalid]="password.invalid && password.touched ? true : null">
            <ng-container *ngIf="password.invalid && password.touched ">
                <p class="error-message" *ngIf="password.errors?.required">
                    Digite a senha!
                </p>
                <p class="error-message" *ngIf="password.errors?.minlength || password.errors?.maxlength">
                    A senha deve conter
                    entre {{ getConfigValue('forms.validation.password.minLength') }}
                    a {{ getConfigValue('forms.validation.password.maxLength') }}
                    caracteres
                </p>
            </ng-container>
        </div>

        <!-- <div class="form-control-group accept-group">
      <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
    </div> -->
        <div>
            <button nbButton fullWidth status="success" [disabled]="submitted || !form.valid"
                [class.btn-pulse]="submitted" class='login-button'>
                Entrar
            </button>
        </div>
    </form>
</div>