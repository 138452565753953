import { Injectable } from '@angular/core';

declare var ga;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsServiceService {

  constructor() { }

  public eventEmmiter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: any = null) {
      ga('send', 'event', eventName, eventCategory, eventValue);
  }

  public setCurrentUser(userId: string) {
    ga('set', 'userId', userId); // Defina o ID de usuário usando o user_id conectado.
  }
}
